export const environment = {
    production: true,
    logRocket: false,
    baseUrl: 'https://data.stage.bookeeapp.com/',
    baseApiUrlAutomation: "https://automations.dev.bookeeapp.com/",
    newCrmUrl: "https://crm.app.stage.bookeeapp.com",
    baseUrlCampaign: "https://campaign.stage.onbookee.com/",
    pusherKey: 'f590a2a24329e5880c12',
    intercomAppId: 'q20fk3po',
    chargebee: 'bookeeapp-test',
    chargebee_api_key: 'test_FpAnpoA1ueGS9rFK1jkqA0jzu5Doh3XH',
    adyenMode: 'test',
    env_name: 'stage',
    posthog_api_key: 'phc_cvIqiKhhcEecQsLG75c9Tz4om1B2U6rxcoz0Eb2rZn1',
    ai_base_url: 'https://anton.stage.bookeeapp.com',
    challenge:'https://5e517f4d6897.ap-south-1.sdk.awswaf.com/5e517f4d6897/3a6c126da886/challenge.js',
    google_secret: "AIzaSyDkJ_WnY7vm1jTHUYI8YWREXSvvjNkfcoM&libraries=places"
};
